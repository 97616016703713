body {
  margin: 0;
  padding: 0;
}

#root {
  /* margin: 0;
  padding: 0; */
  background-color: #01C1CB;
  /* width: 100vw;
  height: 100vh; */
}
